import React from "react";
import {
  Grid,
  Divider,
  Hidden,
  Link,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import * as Styles from "./styles/InformationPagesStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link as RouterLink } from "react-router-dom";
import { IMsalContext } from "../../authentication/MsalContext";
import { useMsal } from "../../authentication/MsalProvider";
import { ISalvageClientSettings } from "../../interfaces/ISalvageClientSettings";
import { GetClientSettings } from "../../services/SettingsService";
import { getStaticIncrements } from "../../services/AuctionService";

const clientSettings: ISalvageClientSettings = GetClientSettings();

const HelpAndFaqContainer: React.FC = () => {
  const msal: IMsalContext = useMsal();
  const classes = Styles.InformationPagesStyles();
  const [bidIncrements, setBidIncrements] = React.useState<any[]>([]);

  React.useEffect(() => {
    getStaticIncrements().then(result => {
      if (result.parsedBody != null) {
        setBidIncrements(result.parsedBody);
      }
    }).catch(async response => {

    });
  }, []);

  const register = () => {
    msal.instance.loginRedirect({
      scopes: [clientSettings.B2CSettings.TokenScope],
      authority: clientSettings.B2CSettings.TenantSignUp,
    });
  };



  return (
    <div>
      <Grid container>
        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            Information
          </Typography>
        </Grid>

        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>

        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            The SalvageMarket web site has been developed to be one of the leading online marketplaces for salvage offering user friendly, open and
            transparent auctions, for buyers of total loss salvage. The site is accessible 24-7 and, as each piece of salvage is stored at one of our
            Seller's sites, it can be viewed in person by prior arrangement with the Seller.
          </Typography>

          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            Please read the following Frequently Asked Questions which should answer any queries you may have. If your query isn't answered here or
            within our{" "}
            <Link component={RouterLink} color="primary" to={"/TermsConditions"}>
              Terms and Conditions
            </Link>{" "}
            please contact your local Seller directly.
          </Typography>
        </Grid>

        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            Registration
          </Typography>
        </Grid>

        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>

        <Grid item xs={12} lg={8} className={classes.container}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">How do I Register for an Account?</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <List>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>1.</ListItemIcon>
                      <Typography variant="body1">
                        Click the{" "}
                        <Link style={{ cursor: "pointer" }} color="primary" onClick={() => register()}>
                          Register Here
                        </Link>{" "}
                        link displayed on most pages on the site.
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>2.</ListItemIcon>
                      <ListItemText primary="Complete all sections of the Application Form." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>3.</ListItemIcon>
                      <ListItemText primary="Choose a plan.  Free subscription (limited access to lots) or subscribe and pay the fee online via Debit/Credit card to access all lots for sale" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>4.</ListItemIcon>
                      <ListItemText primary="Supply standard identification as outlined in the registration process." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>5.</ListItemIcon>
                      <ListItemText primary="You will receive an activation email once your ID & Payment have been received" />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">What identification do I need for registration?</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    All utility bills / bank statements / credit card bills must be in your name and with your address and be dated within the last
                    three months.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography color="primary">Standard Identification:</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    A picture identification (e.g. passport or photocard driving licence) plus one of the following documents:
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <List>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="A Utility bill (Gas / Electric / Telephone)." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="A Current community charge bill." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="A Bank statement or credit card statement." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="An Inland Revenue statement or bill." />
                    </ListItem>
                  </List>
                </Grid>

                <Grid item xs={12}>
                  <Typography color="primary">Additional Identification:</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    If the address on your driving licence is different to the address you registered with you will need to upload a copy of your
                    licence plus two of the above documents:
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <List>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="A utility bill (Gas / Electric / Telephone)." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary=" A bank statement or credit card statement." />
                    </ListItem>
                  </List>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    If you have registered a Business name you will need to upload a copy of your driving licence plus one of the following documents
                    confirming your business details:
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <List>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="A bank statement." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="A VAT registration certificate." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="A Certificate of incorporation (if a Limited Company)." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="A Company letterhead with Director or Partner details." />
                    </ListItem>
                  </List>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    If you are not resident in the UK you will need to upload a copy of your passport or photocard driving licence/National photo ID
                    plus one of the following documents confirming your address:
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <List>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="A utility bill (Gas / Electric / Telephone)." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="A bank statement or credit card statement." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="A Tax Bill or Government Letter." />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">Why has my application been rejected?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    An Application can be rejected due to:
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <List>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="Your application is linked to an existing account – duplicate accounts are not allowed." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="You have already been suspended from our auction. Once you have been suspended, you cannot re-register." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="If the details on your ID do not match your account registration details." />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">When can I start bidding?</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    You can start bidding when the registration approval process is completed and payment has been made.
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            Free Subscription
          </Typography>
        </Grid>

        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>

        <Grid item xs={12} lg={8} className={classes.container}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">What is a free subscription?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    <p>
                      The e2e auction platform needs to offer a solution which will allow a seller to fulfil contractual obligations enabling bidders access to private auction entries without paying the subscription fee.
                      These auction entries would be specific auction types such as Police and Local Authority etc.
                    </p>
                    <p>
                      All bidders who have paid a subscription fee will be able to bid on all lots on the platform including the private auctions.
                    </p>
                    <p>
                      Your chosen subscription plan will determine which lots you have access to bid on.
                      If you have subscribed to the free plan only this will limit the lots you are able to place bids on, you can upgrade at any time.
                    </p>
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            Searching
          </Typography>
        </Grid>

        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>

        <Grid item xs={12} lg={8} className={classes.container}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">How can I Find Salvage I Wish to Purchase?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    There are a number of ways you can search for Salvage on the web site
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={`${classes.orangeText} ${classes.textPadding}`}>
                    Searching:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <List>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="Click ‘Auction Lots’ at the top of the homepage then ‘Search’." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="At the top of the Search Results page there are a number of boxes you can use to help you search for salvage you are interested in purchasing. You can display only vehicles of a certain make or those from a Seller in a particular Region etc or you can type keywords such as 'Range Rover' or 'AMG' to narrow down your search results." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="You can also use a drop down box to list the salvage lots in a particular order ie 'Closing Time', 'Manufacturer', 'Year of Registration'" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="Use the ‘quick search box’ on the homepage" />
                    </ListItem>
                  </List>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={`${classes.orangeText} ${classes.textPadding}`}>
                    Finding specific lots:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <List>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="Click ‘Auction Lots’ and choose from cars, motorbikes or commercial." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="Click any of the 'Editor's picks' on the homepage." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="Featured listing are on the homepage." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="Click the ‘Auction times’ at the top of the home page." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="This link displays a calendar of auctions our Sellers have coming up. You can view all the lots a Seller has listed by clicking their link on this page and option to add to your calendar for handy reminder." />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            Viewing
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">How can I view a Lot before bidding?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    Please contact the Seller directly to view the lot. We recommend customers try to view lots prior to bidding; all lots are "sold
                    as seen". The photographs and Damage Description are for guidance only, however our Sellers endeavour to ensure that the details
                    provided are to the best of their ability.
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            Bidding
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">How do I bid on an auction lot I wish to purchase?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    Salvage Market operates a proxy bidding auction. This means that the highest bidder wins the item providing the auction reserve has been met.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    Placing a proxy bid means that you are stating the maximum amount that you are willing to pay for the item. Your first bid will then be the amount required to outbid any current bid, and thereafter an automatic bid will be placed on your behalf up to the maximum amount you have stated increasing in the increments set out below according to the current bid value.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    Note: if the auction you bid on has a reserve the first bid you place will automatically increase up to the Reserve price or your limit whichever is the smaller. If you win the auction the price you pay will be the current highest bid and not the proxy bid limit  you placed (unless you are bid up to that amount by another bidder).
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="primary">Bid increment</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    The increment amount applied varies according to the current bid price range. Subject to change, the bid increments are based on the following bid values:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Bid Range (£)</TableCell>
                          <TableCell>Fixed Bid Increment (£)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bidIncrements.map((item: any, index: number) => (
                          <TableRow>
                            {item.lowerBand == 25001 ? (
                              <TableCell>{item.lowerBand}+</TableCell>
                            ) : (
                              <TableCell>{item.lowerBand}-{item.upperBand}</TableCell>
                            )}
                            <TableCell>{item.bidIncrement}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">What is auto extend?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    Auto extend is a feature that extends an auction beyond its provisional closing time by 15 seconds every time a new bid is placed.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    The auto extend feature only occurs when bids are placed in the final 60 seconds of an auction and will continue to auto-extend in
                    15 second windows until no further bids have been placed.
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">Will the vehicle be Supplied with a Registration Document V5?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    We aim to provide a V5 for all cat N, D, X & U vehicles where possible, the listing will clearly state if the V5 is available. No
                    cat C or S vehicles will be supplied with a V5. Please note that false VIN plates must be corrected prior to the vehicle being
                    used on the road.
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">What is the ABI category?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    ABI stands for the Association of British Insurers which is UK’s leading financial services trade association. The ABI category is
                    an indication of the level of the damage to a vehicle.
                  </Typography>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    Please follow this link for more information{" "}
                    <Link
                      style={{ cursor: "pointer" }}
                      color="primary"
                      target="_blank"
                      href="https://www.gov.uk/scrapped-and-written-off-vehicles/insurance-writeoffs"
                    >
                      https://www.gov.uk/scrapped-and-written-off-vehicles/insurance-writeoffs
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            Payment
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">How do I pay for an auction lot I have won?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    You will need to contact the Salvage Seller 24 hours prior to collection (within business hours) and pay the Seller for the item
                    by cleared funds before or when you pick it up.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <List>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="If you are unsure what payment method to use please discuss this with the Seller when calling to organise collection." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="Payment in full must be made within 72 Hours of the date of sale." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="Storage charges will apply if the vehicle is not collected from the seller within 7 calendar days." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="Failure to comply with our terms of business will result in you being liable for late payment and storage Fees." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="Any queries concerning discrepancies with the damage description and images or vehicle details should be discussed with the Salvage Seller prior to removing the vehicle from the site." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="Please ensure that when collecting a vehicle from a site that yourself or your agent follow the site rules and regulations." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="Please ensure that you arrive at the site a minimum of 30 minutes before closing - allowing ample time for loading & collection." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="The salvage Seller has the right to refuse entry to anyone not abiding by the site rules & regulations." />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={`${classes.textPadding} ${classes.orangeText}`}>
                    Note: There is a buyers premium
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">How do I pay for a subscription?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    You can pay for a new subscription on-line from “YOUR ACCOUNT”
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <List>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        1.
                      </ListItemIcon>
                      <ListItemText primary="Login to your account." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        2.
                      </ListItemIcon>
                      <ListItemText primary="Go to “YOUR ACCOUNT”." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        3.
                      </ListItemIcon>
                      <ListItemText primary="Go to “Your Details”." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className={classes.orangeText}>
                        4.
                      </ListItemIcon>
                      <ListItemText primary="Scroll to the bottom of the page and click on ‘make a subscription payment’." />
                    </ListItem>
                  </List>
                </Grid>

              </Grid>
            </AccordionDetails>
          </Accordion>

        </Grid>
        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            Collection
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">How do I arrange collection?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    Collection details can be found on the Winning Bid sales document. Please note, you may need an appointment to visit some of the
                    collection sites.
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            Issues
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">How do I deal with Vehicle issues?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    When at the Sellers site it is vitally important that you check the salvage to ensure that you are satisfied with its condition
                    before you remove it from the site.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    Removal of the salvage from the site is your statement that you are happy with your purchase.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    This applies whether you collect the salvage personally or appoint a third party to collect on your behalf and no refunds or
                    compensation will be discussed after this time.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    Should any material issues be identified with the salvage, please discuss these with the Seller.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    Vehicles will not be released from Seller sites until they are paid for in full by cleared funds.
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">Who do I contact if I am experiencing problems with the auction site?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    The SalvageMarket auction site has been developed to be one of the leading online marketplaces for salvage offering user friendly,
                    open and transparent auctions, for buyers of total loss salvage. The site is accessible 24-7 and, as each piece of salvage is
                    stored at one of our Seller's sites, it can be viewed in person by prior arrangement with the Seller.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom className={classes.textPadding}>
                    If your query isn't answered within the FAQ's or within our 'Terms and Conditions' please contact e2e at
                    assist@salvagemarket.co.uk
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
};

export default HelpAndFaqContainer;
