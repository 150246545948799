import { makeStyles } from "@material-ui/core/styles";

export const SearchBarStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  searchOptionListScroll: {
    maxHeight: "300px",
    overflow: "auto",
    marginTop: "8px",
  },
  buttonGroupFullHeight: {
    height: "100% !important",
  },
  searchOptionList: {
    zIndex: 999,
  },
  searchTextBox: {
    // maxHeight: "40px;",
  },
  container: {
    width: "100%",
    //backgroundColor: "#EDEDED",
  },
  box: {
    width: "100%",
    //backgroundColor: "#EDEDED",
    paddingTop: "25px",
    marginBottom: "20px",
    [theme.breakpoints.down('md')]: {
      paddingTop: '0',
    },
  },
  resetButton: {
    marginLeft: "8px",
  },
  filterHeader: {
    weight: "400",
    fontSize: "20px",
    marginBottom: "20px",
  },

  searchModalContentBox: {
    padding: "15px",
    border: "2px solid #C7C7C7",
    borderRadius: "8px"
  },

  customGridSpacing: {
    margin: "3px 0", // Adjust the margin as needed
  },
  savedSearchIcon: {
    display: "flex",
    alignItems: "center",
    marginRight: "1.5rem",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    [theme.breakpoints.down('md')]: {
      width: "30%",
      flexDirection: "row",
    },
  },
  customSpacing: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    '& span': {
      textTransform: "uppercase"
    }
  },
  filterVehicles: {
    // background: "#ffffff",
    //  color: "#EC6E13",
  },
  mobileOnly: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  goFullScreen: {
    [theme.breakpoints.down('md')]: {
      minWidth: "100% !important",
      height: "100vh !important",
      maxHeight: "100vh !important",
      borderRadius: "0px !important",
    },
  },
  freeBidder: {
    // marginTop: '0.75rem',
    paddingRight: '8px',
    paddingLeft: '8px',
    borderRadius: '6px',
    fontWeight: 400,
    fontSize: '13px'
  },
  checkbox: {
    "& .MuiSvgIcon-root": {
      fontSize: 33, // Adjusts checkbox icon size
    },
    "&.Mui-checked": {
      color: "#EC6E13", // Apply orange color only when checked
    },
    "&:not(.Mui-checked)": {
      color: "rgba(0, 0, 0, 0.3)", // darker border when unchecked
    },
  }
}));
