import md5 from "crypto-js/md5";
import Base64 from "crypto-js/enc-base64";
import { BidStatusType } from "../../components/bid/types/BidStatusType";
import { BidderStatus } from "../bidders/IBidder";
import { Auction } from "../../services/AuctionService";

export interface ILatestBid {
  auctionId: string;
  eTag: string;
  increment: number;
  amount?: number;
  proxyAmount?: number;
  auctionInfo: IAuctionInfo;
  bidNonce: string | undefined;
}

export interface ILatestBidHistoryInfo {
  hashOfBidderAndETag: string;
  amount: number;
  bidDateTime: string;
}

export interface IAuctionInfo {
  hashOfWinningBidderAndETag?: string;
  endDateTimeUtc: string;
  auctionStatusCode: number;
  isReserveMet: boolean | null;
  totalBids: number;
  hasEverBidOn: boolean;
}

const isHashForThisBidder = (hash: string | null | undefined, bidderId: string | null | undefined, eTag: string | null | undefined): boolean => {
  if (!hash || !bidderId || !eTag) return false;

  const checksum = md5(bidderId + eTag);
  const base64 = Base64.stringify(checksum);

  return base64 === hash;
};

export const isBidderWinning = (latestBid: ILatestBid | null, bidderId: string | null) => {
  if (latestBid === null) return false;
  return isHashForThisBidder(latestBid.auctionInfo.hashOfWinningBidderAndETag, bidderId, latestBid.eTag);
};

export const canBidOn = (latestBid: ILatestBid | null) => latestBid?.auctionInfo.auctionStatusCode === 200;

export const canBidOnAuction = (auction: Auction, latestBid: ILatestBid | null, isLoggedIn: boolean, bidderStatus: BidderStatus) => {
  if (!latestBid) return false;
  if (!isLoggedIn) return false;
  if (!canBidOn(latestBid)) return false;
  if (bidderStatus === BidderStatus.Member) return false;
  if (bidderStatus === BidderStatus.AwaitingIdenfificaiton ||
      bidderStatus === BidderStatus.AwaitingValidation ||
      bidderStatus === BidderStatus.FailedValidation ||
      bidderStatus === BidderStatus.PotentialDuplicate) return false;
  if ((bidderStatus === BidderStatus.SubscriptionExpired || bidderStatus === BidderStatus.AwaitingPayment) && !auction.metadata.isFreeSubscription) return false;
  // if (bidderStatus !== BidderStatus.ActiveFree && bidderStatus !== BidderStatus.ActiveFull) return false;
  // if (bidderStatus === BidderStatus.ActiveFree && !auction.metadata.isFreeSubscription) return false;

  return true;
};

const isStatusCode = (latestBid: ILatestBid | null, code: number) => latestBid?.auctionInfo?.auctionStatusCode === code;
const isActive = latestBid => isStatusCode(latestBid, 200);
const isSuspended = latestBid => isStatusCode(latestBid, 210);
const isEndedAndWon = latestBid => isStatusCode(latestBid, 300);
const isEndedButNotWon = latestBid => isStatusCode(latestBid, 310);
const isEndedButSuspended = latestBid => isStatusCode(latestBid, 320);
const isCollectedRecently = latestBid => isStatusCode(latestBid, 350);
const isCompletedAsLotCollected = latestBid => isStatusCode(latestBid, 400);
const isCompletedAsArchived = latestBid => isStatusCode(latestBid, 410);
const isCompletedAsReListed = latestBid => isStatusCode(latestBid, 420);

export const calculateBidStatus = (
  auction: Auction,
  latestBid: ILatestBid | null,
  bidderId: string | null,
  isLoggedIn: boolean,
  bidderStatus: BidderStatus
): BidStatusType => {
  if (auction.id !== latestBid?.auctionId) return BidStatusType.Unknown;

  const hasEverBid = latestBid?.auctionInfo.hasEverBidOn ?? false;
  const isWinningBid = isBidderWinning(latestBid, bidderId);
  const hasReserve = latestBid?.auctionInfo?.isReserveMet !== null;
  const isReserveMet = latestBid?.auctionInfo?.isReserveMet === true;

  if (!latestBid) return BidStatusType.Unknown;
  if (!isLoggedIn) return BidStatusType.LoggedOut;
  if (
    bidderStatus === BidderStatus.AwaitingIdenfificaiton ||
    bidderStatus === BidderStatus.AwaitingValidation ||
    bidderStatus === BidderStatus.FailedValidation ||
    bidderStatus === BidderStatus.PotentialDuplicate
  ) {
    return BidStatusType.AwaitingIdVerification;
  }
  if (bidderStatus === BidderStatus.Member) return BidStatusType.MemberAccount;
  if ((bidderStatus === BidderStatus.SubscriptionExpired || bidderStatus === BidderStatus.AwaitingPayment) && !auction.metadata.isFreeSubscription) return BidStatusType.NoSubscription;
  //if (bidderStatus !== BidderStatus.ActiveFree && bidderStatus !== BidderStatus.ActiveFull) return BidStatusType.NotActive;
  if (bidderStatus === BidderStatus.ActiveFree && !auction.metadata.isFreeSubscription) return BidStatusType.AccountUpgradeRequired;

  if (isActive(latestBid) && !hasEverBid) return BidStatusType.NoBidPlaced;
  if (isActive(latestBid) && hasEverBid && !isWinningBid) return BidStatusType.OutBid;
  if (isActive(latestBid) && hasEverBid && isWinningBid && hasReserve && !isReserveMet) return BidStatusType.WinningReserveNotMet;
  if (isActive(latestBid) && hasEverBid && isWinningBid) return BidStatusType.Winning;

  if (isSuspended(latestBid) && hasReserve && !isReserveMet) return BidStatusType.AuctionSuspendedReserveNotMet;
  if (isSuspended(latestBid)) return BidStatusType.AuctionSuspended;

  if (isEndedAndWon(latestBid) && !hasEverBid) return BidStatusType.BiddingClosed;
  if (isEndedAndWon(latestBid) && hasEverBid && !isWinningBid) return BidStatusType.BiddingClosedLost;
  if (isEndedAndWon(latestBid) && hasEverBid && isWinningBid) return BidStatusType.BiddingClosedWon;

  if (isEndedButNotWon(latestBid) && !hasEverBid) return BidStatusType.BiddingClosed;
  if (isEndedButNotWon(latestBid) && hasEverBid && !isWinningBid) return BidStatusType.BiddingClosedLost;
  if (isEndedButNotWon(latestBid) && hasEverBid && isWinningBid && hasReserve && !isReserveMet) return BidStatusType.BiddingClosedWonReserveNotMet;
  if (isEndedButNotWon(latestBid) && hasEverBid && isWinningBid) return BidStatusType.BiddingClosedWon;

  if (isEndedButSuspended(latestBid)) return BidStatusType.BiddingClosedWhileSuspended;

  if (isCollectedRecently(latestBid) && !hasEverBid) return BidStatusType.BiddingClosed;
  if (isCollectedRecently(latestBid) && hasEverBid && !isWinningBid) return BidStatusType.BiddingClosedLost;
  if (isCollectedRecently(latestBid) && hasEverBid && isWinningBid) return BidStatusType.BiddingClosedWon;

  if (isCompletedAsLotCollected(latestBid) && !hasEverBid) return BidStatusType.BiddingClosed;
  if (isCompletedAsLotCollected(latestBid) && hasEverBid && !isWinningBid) return BidStatusType.BiddingClosedLost;
  if (isCompletedAsLotCollected(latestBid) && hasEverBid && isWinningBid) return BidStatusType.BiddingClosedWon;

  if (isCompletedAsArchived(latestBid)) return BidStatusType.BiddingClosed;
  if (isCompletedAsReListed(latestBid)) return BidStatusType.BiddingClosed;

  return BidStatusType.Unknown;
};
