import React from "react";
import * as Styles from "../styles/TitleContainerStyles";
import { Auction } from "../../../services/AuctionService";
import { Grid, Chip } from "@material-ui/core";

const TitleContainer: React.FC<{ auction: Auction }> = ({ auction }) => {
  const classes = Styles.TitleContainerStyles();

  const categoryLabel = () => {
    if (auction.metadata.damageCategory !== "") {
      return (
        <Chip
          size="small"
          color="primary"
          label={`CATEGORY ${auction.metadata.damageCategory ? auction.metadata.damageCategory : "Unknown"}`}
          className={classes.category}
        />
      );
    }
  };

  const freeBidderLabel = () => {
    if (auction.metadata.isFreeSubscription) {
      return (
        <Chip
          size="small"
          color="secondary"
          label="FREE BIDDER LISTING"
          className={classes.freeBidder}
        />
      );
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}
        className={classes.gridItem}>
        {categoryLabel()}
        {freeBidderLabel()}
        <h2 className={classes.title}>{auction.metadata.title}</h2>
      </Grid>
    </Grid>
  );
};

export default TitleContainer;
