import { fade, makeStyles } from "@material-ui/core/styles";
const borderRadius = "16px";

export const HomepageBillboardSlideStyles = makeStyles(theme => ({
    card: {
        margin: "8px",
        borderRadius: borderRadius,
        minHeight: "530px",
    },
    cardMediaContainer: { padding: "0px" },
    cardMedia: {
        height: "100%",
    },
    watchingContainer: {
        position: "absolute",
        top: "8px",
        left: "8px",
        height: "60px",
        width: "60px",
    },
    watching: {
        cursor: "pointer",
        color: theme.palette.secondary.main,
        background: `linear-gradient(-45deg, transparent 50%, ${theme.palette.primary.main} 50.5%)`,
    },
    notWatching: {
        cursor: "pointer",
        color: theme.palette.primary.main,
        background: `linear-gradient(-45deg, transparent 50%, ${theme.palette.secondary.main} 50.5%)`,
    },
    currentBitAmount: {
        fontWeight: "bold",
        paddingRight: theme.spacing(3),

        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "column",
    },
    freeBidder: {
        // marginTop: '0.75rem',
        paddingRight: '8px',
        paddingLeft: '8px',
        borderRadius: '6px',
        fontWeight: 400,
        fontSize: '13px'
    }
}));