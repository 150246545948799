import React from "react";
import { Link } from "react-router-dom";
import { BidStatusType } from "../types/BidStatusType";
import { ISalvageClientSettings } from "../../../interfaces/ISalvageClientSettings";
import { GetClientSettings } from "../../../services/SettingsService";
import { IMsalContext } from "../../../authentication/MsalContext";
import { useMsal } from "../../../authentication/MsalProvider";

export const BidStatus: React.FC<{
  bidStatus: BidStatusType;
  bidStatusTextStyle: string;
}> = ({ bidStatus, bidStatusTextStyle }) => {
  const clientSettings: ISalvageClientSettings = GetClientSettings();
  const msal: IMsalContext = useMsal();
  if (bidStatus === BidStatusType.AccountUpgradeRequired) {
    return (
      <h2 className={bidStatusTextStyle}>
        <Link to="/Renewal" style={{ color: "white" }}>
          UPGRADE REQUIRED
        </Link>
      </h2>
    );
  }

  const login = () => {
    msal.instance.loginRedirect({
      scopes: [clientSettings.B2CSettings.TokenScope],
    });
  };

  //prettier-ignore
  const statusAsString = (type: BidStatusType) => {
    switch (type) {
      case BidStatusType.MemberAccount: return "MEMBER ACCOUNT";
      case BidStatusType.Winning: return "BID WINNING";
      case BidStatusType.WinningReserveNotMet: return "BID WINNING";
      case BidStatusType.OutBid: return "OUTBID";
      case BidStatusType.NoBidPlaced: return "PLACE BID";
      case BidStatusType.LoggedOut: return "LOGIN TO BID";
      case BidStatusType.BiddingClosed: return "AUCTION CLOSED";
      case BidStatusType.BiddingClosedLost: return "CLOSED - NOT WON";
      case BidStatusType.BiddingClosedWhileSuspended: return "SUSPENDED CLOSED";
      case BidStatusType.BiddingClosedWon: return "AUCTION WON";
      case BidStatusType.BiddingClosedWonReserveNotMet: return "RESERVE NOT MET";
      case BidStatusType.AuctionSuspendedReserveNotMet: return "AUCTION SUSPENDED";
      case BidStatusType.AuctionSuspended: return "AUCTION SUSPENDED";
      case BidStatusType.AwaitingIdVerification: return "AWAIT ID APPROVAL";
      case BidStatusType.NoSubscription: return "UPGRADE TO BID";
      case BidStatusType.NotActive: return "ACCOUNT NOT ACTIVE";
      default:
        return "Checking for you...";
    }
  };

  return (
    <h2 onClick={bidStatus === BidStatusType.LoggedOut ? login : undefined} className={bidStatusTextStyle} 
      style={{ cursor: bidStatus === BidStatusType.LoggedOut ? 'pointer' : 'default' }}>
      {statusAsString(bidStatus)}
    </h2>
  );
};
