import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { currencyFormat } from "../../helpers/text-format/TextFormat";
import { IStripeProduct } from "../../interfaces/payments/IStripeProduct";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  grid: {
    backgroundColor: "#EBEBEB",
    padding: "30px",
    borderRadius: "10px",  
    marginBottom: "15px",
    '&.active': {  
      backgroundColor: "#EC6E13",
      color: "#FFFFFF",
      '&.MuiButton-containedSecondary': {
        backgroundColor: "#FFFFFF",
      },
    },
    '&.MuiGrid-item': {
      marginRight: "15px",
      marginBottom: "15px",
      height: "100%",
    },
  },
});

interface IProps {
  stripeProduct: IStripeProduct;
  selectedStripeProduct?: IStripeProduct;
  isSignUpMode: boolean;
  stripeProductChanged: (toPost: IStripeProduct) => void;
}

export const StripePaymentProduct: React.FC<IProps> = ({ stripeProduct, selectedStripeProduct, isSignUpMode, stripeProductChanged }) => {
  const classes = useStyles();

  const getButtonLabel = () => {
    if (isSignUpMode) {
      return selectedStripeProduct?.id == stripeProduct.id ? 'Selected' : 'Select';
    } else {
      if (stripeProduct.id === 'FREE')
      {
        return "Current Subscription";
      }

      return selectedStripeProduct?.id == stripeProduct.id ? 'Selected' : 'Select';
    }
  }

  const getContainerActive = () => {
    if (isSignUpMode) {
      return selectedStripeProduct?.id === stripeProduct.id;
    } else {
      return selectedStripeProduct?.id === stripeProduct.id && stripeProduct.id !== 'FREE';
    }
  }

  return (
    <Grid
      item xs={12} md={4} style={{marginBottom: "15px",}}>
      <Grid
        item className={`${classes.grid} ${ getContainerActive() ? 'active' : ''}`}>
        <Typography
          style={{
            paddingBottom: "15px",
            fontWeight: "bold",
          }}
          component="h3" variant="h6">
          {stripeProduct?.name}<br></br>
        </Typography>
        <Typography
          style={{
            paddingBottom: "30px",
            fontWeight: "bold",
          }}
          component="h3" variant="h6">
          {currencyFormat(stripeProduct?.amount ? (stripeProduct?.amount / 100) : 0)}{stripeProduct.vatInclusive ? '' : '(+VAT)'}<br></br>
        </Typography>
        <Typography
          style={{
            paddingBottom: "30px",

          }}
        >
          {stripeProduct?.description}<br></br>
        </Typography>
        <Grid item xs={12} md={12}>
          <Button
            style={{
              minWidth: '215px',
              backgroundColor: selectedStripeProduct?.id == stripeProduct.id ? 'white' : undefined,
              whiteSpace: "nowrap"
            }}
            type="button" color={selectedStripeProduct?.id == stripeProduct.id ? 'secondary' : 'primary'} variant="contained" onClick={() => stripeProductChanged(stripeProduct)}
            disabled={!stripeProduct.active || (!isSignUpMode && stripeProduct.id === 'FREE')}>
            {getButtonLabel()}
          </Button>
        </Grid>
      </Grid></Grid>
  )
};

