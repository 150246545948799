import * as React from "react";
import * as Styles from "../styles/SearchBarStyles";
import * as queryString from "query-string";
import { Grid, Button, TextField, InputAdornment, Theme, useMediaQuery, Container, Box, Typography, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Icon, Chip, Snackbar, Checkbox, FormControlLabel, styled } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { IVehicleSearchConfiguration } from "../../../interfaces/search/IVehicleSearchConfiguration";
import * as SearchService from "../../../services/SearchService";
import { SearchBarOption } from "./SearchBarOption";
import { ISearchBarProps } from "../../../interfaces/search/ISearchBarProps";
import { DistanceOptions, OrderBy } from "../../../interfaces/search/ISearchOptions";
import { CalendarToday, Close, FilterList, Room, DirectionsCar, VpnKey, LocalGasStation, Speed, Settings } from "@material-ui/icons";
import { AppContext } from "../../../contexts/AppContext";
import { useSnackbar } from "notistack";
import SavedSearchesContainer from "../../saved-searches/SavedSearchesContainer"; // Import your component
import { SearchResultsTopBar } from "./SearchResultsTopBar";

export const SearchBar: React.FC<ISearchBarProps> = ({ options, loadingSearchResults, searchOptionsUpdated, resetHandler, orderByChanged, onQueryStringUpdated, setIsFilterActive }) => {
  const classes = Styles.SearchBarStyles();
  const context = React.useContext(AppContext);

  const [searchOptions, setSearchOptions] = React.useState(options);
  const [configuration, setConfiguration] = React.useState<IVehicleSearchConfiguration | undefined>();
  const [textSearch, setTextSearch] = React.useState<string>(searchOptions.searchText);
  const [orderBySelected, setOrderBySelected] = React.useState<OrderBy>(searchOptions.orderBy);
  const [location, setLocation] = React.useState<string>("");
  const [saveSearchModal, setSaveSearchModal] = React.useState(false);
  const [savedSearch, setSavedSearch] = React.useState<SearchService.SavedSearchResult>({} as SearchService.SavedSearchResult);
  const [showSavedSearchesModal, setShowSavedSearchesModal] = React.useState(false); // State for showing the saved searches modal

  const [resetConfirmationModal, setResetConfirmationModal] = React.useState(false);


  const { enqueueSnackbar } = useSnackbar();

  const onReset = () => {
    //getSearchConfigurationMakeOptions();
    resetHandler();
  }

  async function getSearchConfigurationMakeOptions() {
    const response = await SearchService.MasterSearchConfiguration();
    const config: IVehicleSearchConfiguration = response.parsedBody as IVehicleSearchConfiguration;
    if (config !== undefined) {
      setConfiguration(prevConfig => {
        // Ensure prevConfig has a default value if undefined
        const safePrevConfig: IVehicleSearchConfiguration = prevConfig || {} as IVehicleSearchConfiguration;

        return {
          ...safePrevConfig,
          makes: config.makes,
          models: { ...prevConfig?.models, ...config.models },
          vehicleModelMap: prevConfig === undefined ? config.vehicleModelMap : prevConfig?.vehicleModelMap
        }
      });
    }
  }

  async function getSearchConfigurationOptions() {
    const response = await SearchService.SearchConfiguration(searchOptions);
    const config: IVehicleSearchConfiguration = response.parsedBody as IVehicleSearchConfiguration;
    if (config !== undefined) {
      setConfiguration(prevConfig => {
        // Ensure prevConfig has a default value if undefined
        const safePrevConfig: IVehicleSearchConfiguration = prevConfig || {} as IVehicleSearchConfiguration;
        const selectedMakes = searchOptions.make || [];

        // Collect models for the selected makes
        const updatedModels = selectedMakes.reduce((acc, make) => {
          const models = safePrevConfig.vehicleModelMap?.[make] || {};
          return { ...acc, ...models };
        }, {} as Record<string, number>);


        //#region check if models selected are still applicable
        let newModelsList: string[] = [];

        if (searchOptions != null && searchOptions.model != null && searchOptions.model.length > 0) {
          searchOptions.model.forEach(item => {
            if (config.models[item] != null) {
              newModelsList.push(item);
            }
          });
        }


        if (searchOptions != null && searchOptions.make != null && searchOptions.make.length == 0 && newModelsList.length > 0) {
          newModelsList = [];
          handleSearchOptionSet('model', newModelsList);
        }




        if (!(searchOptions.model.length == 1 && searchOptions.model[0] == '') && newModelsList.length != searchOptions.model.length) {
          handleSearchOptionSet('model', newModelsList);
        } else {
          //do nothing the models still exist in the list
        }
        //#endregion

        let makesToUse = safePrevConfig.makes || config.makes;
        if (config != null && config.makes != null && Object.entries(config.makes).length > 0) {

          Object.entries(makesToUse).forEach(([key, value]) => {
            if (config.makes[key] != undefined && config.makes[key] != null) {
              makesToUse[key] = config.makes[key];
            } else {
              makesToUse[key] = 0;
            }
          });
        }


        return {
          makes: makesToUse,
          models: config.models,
          categories: config.categories,
          lotTypes: config.lotTypes,
          fuelTypes: config.fuelTypes,
          transmissions: config.transmissions,
          sellers: config.sellers,
          years: config.years,
          startDrive: config.startDrive,
          subscriptions: config.subscriptions,
          vehicleModelMap: safePrevConfig.vehicleModelMap
        };
      });
    }
  }

  React.useEffect(() => {
    if (configuration !== undefined) {
      //getSearchConfigurationMakeOptions();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOptions.auction, searchOptions.fuelType, searchOptions.damageCategory, searchOptions.transmissionType,
  searchOptions.seller, searchOptions.year, searchOptions.startDrive]);

  React.useEffect(() => {
    async function getConfiguration() {
      if (configuration === undefined) {
        //await getSearchConfigurationMakeOptions();
      }

      await getSearchConfigurationOptions();
    }

    getConfiguration();
  }, [searchOptions]);

  React.useEffect(() => {
    const selectedMakes = searchOptions.make || [];
    const validModels = new Set();

    if (configuration?.vehicleModelMap !== undefined) {
      // Collect all valid models based on selected makes
      selectedMakes.forEach(make => {
        const models = configuration.vehicleModelMap?.[make];
        if (models) {
          Object.keys(models).forEach(model => validModels.add(model));
        }
      });

      // Filter selected models to include only those that are valid
      const filteredModels = (searchOptions.model || []).filter(model => validModels.has(model));

      // Update the search options with the filtered models if necessary
      if (filteredModels.length !== (searchOptions.model || []).length) {
        setSearchOptions(prevOptions => ({
          ...prevOptions,
          model: filteredModels,
        }));
      }
    }
  }, [searchOptions.make]);

  React.useEffect(() => {
    setSearchOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  React.useEffect(() => {
    const bidderPostcode: string = context.bidderState.bidder?.address?.postcode ?? "";
    setLocation(bidderPostcode);
  }, [context.bidderState.bidder]);

  const handleSearchOptionSet = (property: string, value: string[]) => {
    searchOptionsUpdated({ ...searchOptions, [property]: value }, property);
  };

  const handleSearchTextChange = (value: string) => {
    setTextSearch(value);
  };

  const handleSearch = () => {
    var options = { ...searchOptions, searchText: textSearch };
    searchOptionsUpdated(options);

    setIsFilterActive(false);
  };

  const handleLocationChange = () => {
    orderByChanged(orderBySelected, location);
  };

  const showDistanceValidationMessage = () => {
    return location === "" && (searchOptions.orderBy === OrderBy.DistanceAsc || searchOptions.orderBy === OrderBy.DistanceDesc);
  };

  const distanceValidationMessage = () => {
    var message = "";

    if (showDistanceValidationMessage()) {
      message = "Enter a postcode to ";

      if (searchOptions.distance.length !== 0) {
        message = `${message} filter`;
        if (searchOptions.orderBy === OrderBy.DistanceAsc || searchOptions.orderBy === OrderBy.DistanceDesc) {
          message = `${message} and sort`;
        }
      } else if (searchOptions.orderBy === OrderBy.DistanceAsc || searchOptions.orderBy === OrderBy.DistanceDesc) {
        message = `${message} sort`;
      }

      message = `${message} by distance`;
    }

    return message;
  };

  async function saveSearch() {
    if (savedSearch?.name == null || savedSearch?.name == '') {
      enqueueSnackbar('Please enter a Name.', { variant: 'error' });
    } else {
      await SearchService.SaveSearch(
        {
          name: savedSearch?.name,
          freeSubscriptionOnly: searchOptions.freeSubscriptionOnly,
          postcode: location,
          distance: searchOptions.distance,
          makes: searchOptions.make,
          models: searchOptions.model,
          fuelTypes: searchOptions.fuelType,
          transmissionTypes: searchOptions.transmissionType,
          years: searchOptions.year,
          damageCategories: searchOptions.damageCategory,
          startDrives: searchOptions.startDrive,
          queryString: queryString.stringify({ ...searchOptions, pageNumber: 0 }, { arrayFormat: 'bracket' })
        } as SearchService.SavedSearchResult
      );
      enqueueSnackbar('Your search configuration has been saved.', { variant: 'success', autoHideDuration: 5000 });
      setSaveSearchModal(false);
    }
  }

  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  // const StyledCheckbox = styled(Checkbox)({
  //   "& .MuiSvgIcon-root": {
  //     fontSize: 31,
  //   },
  // });

  return (
    <>
      {saveSearchModal && (
        <Dialog open={saveSearchModal} onClose={() => setSaveSearchModal(false)} fullWidth classes={{ paper: classes.goFullScreen }}>
          <DialogTitle>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                Save Search
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => setSaveSearchModal(false)}
                >
                  <Close></Close>
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>

          <DialogContent dividers>
            <Box className={classes.searchModalContentBox}>
              <Grid container spacing={1}>
                <Grid item xs={12} style={{ marginBottom: "1rem" }}>
                  {!searchOptions?.damageCategory.includes("") && searchOptions?.damageCategory?.map(cat =>
                  (
                    <Chip size="small" color="primary" label={"Category " + cat} style={{ textTransform: "uppercase", marginRight: "0.5rem" }} />
                  )
                  )}

                  {searchOptions?.freeSubscriptionOnly && (
                    <Chip
                      size="small"
                      color="secondary"
                      label="FREE LISTINGS ONLY"
                      className={classes.freeBidder}
                    />
                  )}
                </Grid>

                <Grid item xs={9}>
                  <TextField
                    style={{ height: "32px", width: "100%" }}
                    value={savedSearch.name}
                    onChange={value => {
                      setSavedSearch({ ...savedSearch, name: value.target.value.toString() });
                    }}
                    onKeyPress={event => {
                      if (event.key === "Enter") {
                        saveSearch();
                      }
                    }}
                    placeholder="Name"
                  ></TextField>
                </Grid>

                <Grid item xs={3} alignContent="flex-end">
                  <Room></Room>
                  <span>{location}</span>
                </Grid>

                {searchOptions?.distance.length > 0 && !searchOptions.distance.includes("") && (
                  <Grid item className={classes.savedSearchIcon}>
                    <Speed />
                    {searchOptions?.distance.join(", ")}
                  </Grid>
                )}

                {searchOptions?.make.length > 0 && !searchOptions.make.includes("") && (
                  <Grid item className={classes.savedSearchIcon}>
                    <DirectionsCar />
                    {searchOptions?.make.join(", ")}
                  </Grid>
                )}

                {searchOptions?.model.length > 0 && !searchOptions.model.includes("") && (
                  <Grid item className={classes.savedSearchIcon}>
                    <DirectionsCar />
                    {searchOptions?.model.join(", ")}
                  </Grid>
                )}

                {searchOptions?.fuelType.length > 0 && !searchOptions.fuelType.includes("") && (
                  <Grid item className={classes.savedSearchIcon}>
                    <LocalGasStation />
                    {searchOptions?.fuelType.join(", ")}
                  </Grid>
                )}

                {searchOptions?.transmissionType.length > 0 && !searchOptions.transmissionType.includes("") && (
                  <Grid item className={classes.savedSearchIcon}>
                    <Settings />
                    {searchOptions?.transmissionType.join(", ")}
                  </Grid>
                )}

                {searchOptions?.year.length > 0 && !searchOptions.year.includes("") && (
                  <Grid item className={classes.savedSearchIcon}>
                    <CalendarToday />
                    {searchOptions?.year.join(", ")}
                  </Grid>
                )}

                {searchOptions.startDrive.includes("Starts") && (
                  <Grid item className={classes.savedSearchIcon}>
                    <VpnKey />
                    Starts
                  </Grid>
                )}

                {searchOptions.startDrive.includes("Drives") && (
                  <Grid item className={classes.savedSearchIcon}>
                    <DirectionsCar />
                    Drives
                  </Grid>
                )}

              </Grid>
            </Box>
          </DialogContent>

          <DialogActions>
            <Grid item xs={12}>
              <Button variant={"contained"} color={"primary"} fullWidth onClick={saveSearch}>
                Save Search
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      )}

      {showSavedSearchesModal && (
        <Dialog open={showSavedSearchesModal} onClose={() => setShowSavedSearchesModal(false)} fullWidth classes={{ paper: classes.goFullScreen }} >
          <DialogTitle>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                Saved Searches
              </Grid>
              <Grid item>
                <IconButton onClick={() => setShowSavedSearchesModal(false)}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <SavedSearchesContainer hideTitle={true} hideCreateNewButton={true} isDialog={true} onViewSearchFromModal={(qs) => {
              if (onQueryStringUpdated) {
                onQueryStringUpdated(qs);
                setShowSavedSearchesModal(false);
                setIsFilterActive(false);
              }
            }} />
          </DialogContent>
        </Dialog>
      )}


      <Box className={classes.box}>
        {configuration !== undefined && (
          <Container className={classes.container}>
            <Typography variant={"h5"} component="h5" align="left" className={classes.filterHeader}>
              Filter Vehicles
            </Typography>

            <Grid
              container
              spacing={1}
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              {context.bidderState.bidderLoaded && (
                <Grid item xs={12}>
                  <Button variant="contained" color={"primary"} className={classes.filterVehicles} fullWidth onClick={() => setShowSavedSearchesModal(true)}>
                    View your saved searches
                  </Button>
                </Grid>
              )}

              <Grid item>
                <TextField
                  style={{ height: "32px", maxWidth: "100%" }}
                  value={location}
                  error={showDistanceValidationMessage()}
                  helperText={distanceValidationMessage()}
                  onChange={value => {
                    setLocation(value.target.value.toString());
                  }}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      handleLocationChange();
                    }
                  }}
                  placeholder="Postcode"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleLocationChange}>
                          <FilterList color="primary" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </Grid>

              <Grid item>
                <FormControlLabel
                  label="Free Listings Only"
                  labelPlacement="end"
                  control={
                  <Checkbox
                    name="freeSubscriptionOnly"
                    checked={searchOptions.freeSubscriptionOnly}
                    onChange={(event) => {
                      const options = { ...searchOptions, freeSubscriptionOnly: event.target.checked };
                      searchOptionsUpdated(options);
                    }}
                    className={classes.checkbox}
                    //style={{ color: "#EC6E13" }} // ✅ No TypeScript issues
                  />
                  } />
              </Grid>

              <Grid item className={classes.customGridSpacing}>
                <SearchBarOption
                  displayName="Auction"
                  propertyName="auction"
                  options={configuration.lotTypes}
                  selectedItemNames={searchOptions.auction}
                  disable={loadingSearchResults}
                  singleSelectOnly={false}
                  onSelectedEvent={handleSearchOptionSet}
                />
              </Grid>
              <Grid item className={classes.customGridSpacing}>
                <SearchBarOption
                  displayName="Category"
                  propertyName="damageCategory"
                  options={configuration.categories}
                  disable={loadingSearchResults}
                  singleSelectOnly={false}
                  selectedItemNames={searchOptions.damageCategory}
                  onSelectedEvent={handleSearchOptionSet}
                />
              </Grid>
              <Grid item className={classes.customGridSpacing}>
                <SearchBarOption
                  displayName="Distance"
                  propertyName="distance"
                  disable={loadingSearchResults}
                  singleSelectOnly={false}
                  options={DistanceOptions}
                  selectedItemNames={searchOptions.distance}
                  onSelectedEvent={handleSearchOptionSet}
                />
              </Grid>
              <Grid item className={classes.customGridSpacing}>
                <SearchBarOption
                  displayName="Make"
                  propertyName="make"
                  disable={loadingSearchResults}
                  singleSelectOnly={true}
                  options={configuration.makes}
                  selectedItemNames={searchOptions.make}
                  onSelectedEvent={handleSearchOptionSet}
                />
              </Grid>
              <Grid item className={classes.customGridSpacing}>
                <SearchBarOption
                  displayName="Model"
                  propertyName="model"
                  disable={loadingSearchResults || (searchOptions.make.length === 0 || searchOptions.make.includes(""))}
                  singleSelectOnly={false}
                  options={configuration.models}
                  selectedItemNames={searchOptions.model}
                  onSelectedEvent={handleSearchOptionSet}
                />
              </Grid>
              <Grid item className={classes.customGridSpacing}>
                <SearchBarOption
                  displayName="Transmission"
                  propertyName="transmissionType"
                  disable={loadingSearchResults}
                  singleSelectOnly={false}
                  options={configuration.transmissions}
                  selectedItemNames={searchOptions.transmissionType}
                  onSelectedEvent={handleSearchOptionSet}
                />
              </Grid>
              <Grid item className={classes.customGridSpacing}>
                <SearchBarOption
                  displayName="Fuel"
                  propertyName="fuelType"
                  disable={loadingSearchResults}
                  singleSelectOnly={false}
                  options={configuration.fuelTypes}
                  selectedItemNames={searchOptions.fuelType}
                  onSelectedEvent={handleSearchOptionSet}
                />
              </Grid>
              <Grid item className={classes.customGridSpacing}>
                <SearchBarOption
                  displayName="Seller"
                  propertyName="seller"
                  disable={loadingSearchResults}
                  singleSelectOnly={false}
                  options={configuration.sellers}
                  selectedItemNames={searchOptions.seller}
                  onSelectedEvent={handleSearchOptionSet}
                />
              </Grid>
              <Grid item className={classes.customGridSpacing}>
                <SearchBarOption
                  displayName="Year"
                  propertyName="year"
                  disable={loadingSearchResults}
                  singleSelectOnly={false}
                  options={configuration.years}
                  selectedItemNames={searchOptions.year}
                  onSelectedEvent={handleSearchOptionSet}
                />
              </Grid>
              {/* REMOVED FOR COMMERCIAL CHANGES IN CR28 */}
              {/* <Grid item>
              <SearchBarOption
                displayName="Subscription"
                propertyName="subscription"
                disable={loadingSearchResults}
                singleSelectOnly={false}
                options={configuration.subscriptions}
                selectedItemName={searchOptions.subscription}
                onSelectedEvent={handleSearchOptionSet}
              />
            </Grid> */}
              <Grid item className={classes.customGridSpacing}>
                <SearchBarOption
                  displayName="Starts & Drives"
                  propertyName="startDrive"
                  disable={loadingSearchResults}
                  singleSelectOnly={false}
                  options={configuration.startDrive}
                  selectedItemNames={searchOptions.startDrive}
                  onSelectedEvent={handleSearchOptionSet}
                />
              </Grid>

              <Grid item className={classes.customGridSpacing} style={{ marginTop: "12px", marginBottom: "12px" }}>
                <Grid container alignItems={xs ? undefined : "center"} alignContent={xs ? undefined : "center"}>
                  <TextField
                    size="small"
                    style={{ backgroundColor: "white" }}
                    disabled={loadingSearchResults}
                    id="textSearch"
                    placeholder="Search e.g. Blue"
                    onChange={e => handleSearchTextChange(e.target.value)}
                    value={textSearch}
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        handleSearch();
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment style={{ cursor: "pointer" }} onClick={() => handleSearch()} position="end">
                          <SearchIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.mobileOnly}>
                <Grid container spacing={1} justifyContent="space-between">
                  <Grid item xs={2}>
                    Sort by:
                  </Grid>
                  <Grid item xs={10}>
                    <SearchResultsTopBar searchOptions={searchOptions} orderByChanged={orderByChanged} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1} style={{ paddingTop: "12px" }} direction="row" justifyContent="flex-start" alignContent="stretch">
                  {context.bidderState.bidderLoaded && (
                    <Grid item xs={8}>
                      <Button variant="contained" color="primary" fullWidth onClick={() => setSaveSearchModal(true)}>
                        Save Search
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs>
                    <Button variant="contained" style={{ background: "#ffffff", color: "#EC6E13" }} color="secondary" fullWidth onClick={() => onReset()}>
                      Reset
                    </Button>
                  </Grid>
                  {xs && ( // This will render only on mobile devices
                    <Grid item xs={12} style={{ marginTop: "15px" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => handleSearch()}>
                        Search
                      </Button>
                    </Grid>
                  )}

                </Grid>
              </Grid>
            </Grid>

          </Container>
        )}
      </Box>
    </>
  );
};
