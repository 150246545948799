import "typeface-inter/inter.css";
import { createTheme } from "@material-ui/core/styles";

const white = "#FFFFFF";
const black = "#000000";
const success = "#43a047";
const error = "#d32f2f";

const orange = "#EC6E13";
const grey = "#D1D1D1";
const darkGrey = "#393939";
const lightGrey = "#F8F8F8";

const darkBlue = "#387AEB";

const salesDocumentHighlight = "#FFFACD";

export const theme = (mainColor = orange) =>
  createTheme({
    typography: {
      fontFamily: "inter",
    },
    palette: {
      primary: {
        main: mainColor,
        contrastText: white,
      },
      secondary: {
        main: grey,
        light: lightGrey,
      },
      background: {
        default: white,
      },
    },
    overrides: {
      MuiAppBar: {
        colorPrimary: {
          boxShadow: "none",
          backgroundColor: "transparent",
        },
      },
    },
  });

export { white, black, success, error, orange, grey, darkGrey, lightGrey, darkBlue, salesDocumentHighlight };
