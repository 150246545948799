import { StyleSheet } from "@react-pdf/renderer";
import { grey, darkGrey, error, salesDocumentHighlight } from "../../../theme";

export const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    margin: "10px",
  },
  rowPaddingBottom14: { flexDirection: "row", paddingBottom: "14px" },
  rowPaddingBottom4: { flexDirection: "row", paddingBottom: "4px" },
  addressContainer: { flexDirection: "row", padding: "0 0 4px 4px", minHeight: "80px" },
  row: { flexDirection: "row" },
  contactBlock: {
    flexDirection: "column",
    textAlign: "right",
    width: "45%",
    position: "absolute",
    top: "0",
    right: "0",
    marginRight: "20px",
    marginBottom: "8px",
  },
  alignCenter: {
    position: "absolute",
    bottom: "50%",
  },
  alignBottom: {
    position: "absolute",
    bottom: "0",
  },
  logoBlock: { width: "50%" },
  logo: {
    height: "48px",
    width: "160px",
  },
  width100: {
    width: "100%",
  },
  width95: {
    width: "95%",
  },
  logoBanner: {
    width: "100%",
    height: "60px",
    flexDirection: "row",
    paddingBottom: "8px",
  },
  buyerBlock: {
    flexDirection: "column",
    width: "25%",
  },
  sellerBlock: {
    flexDirection: "column",
    width: "18%",
  },
  openingDaysBlock: {
    flexDirection: "column",
    width: "18%",
  },
  buyerDetailsTitle: {
    flexDirection: "column",
    width: "25%",
    backgroundColor: grey,
  },
  sellerDetailsTitle: {
    flexDirection: "column",
    width: "36%",
    backgroundColor: grey,
  },
  vehicleDetailsTitle: {
    flexDirection: "column",
    width: "36%",
    backgroundColor: grey,
  },

  bidderFinancesBlock: {
    flexDirection: "column",
    width: "77%",
    marginTop: "4px",
  },
  bidderTotalBlock: {
    flexDirection: "column",
    width: "20%",
    margin: "4px 4px 0 4px",
  },
  greyTextRight: {
    fontSize: 10,
    color: darkGrey,
    textAlign: "right",
  },
  largeText: {
    fontSize: 12,
  },
  mediumText: {
    fontSize: 9,
  },
  text: {
    fontSize: 6,
  },
  title: {
    width: "97%",
    backgroundColor: grey,
    fontSize: 12,
    marginBottom: "2px",
    padding: "2px",
  },
  splitTitle: {
    width: "48.5%",
    backgroundColor: grey,
    fontSize: 12,
    marginBottom: "2px",
    padding: "2px",
  },
  littleGreyTextCentred: {
    color: darkGrey,
    fontSize: 6,
    textAlign: "center",
    padding: "0 12px 0 0",
  },
  totalDueText: {
    color: darkGrey,
    fontSize: 6,
    textAlign: "center",
  },
  littleGreyText: {
    color: darkGrey,
    fontSize: 6,
  },
  littleGreyTextLotDetails: {
    color: darkGrey,
    fontSize: 6,
    paddingTop: 2,
  },
  greyText: {
    color: darkGrey,
    fontSize: 9,
  },
  littleBlackText: {
    fontSize: 6,
  },
  contactWrapper: { flexDirection: "row", marginTop: "16px" },
  contactImageBlock: {
    flexDirection: "column",
    width: "10%",
  },
  contactTextBlock: {
    flexDirection: "column",
    width: "90%",
  },
  contactText: { fontSize: 6 },
  phoneIcon: { height: "6px", width: "6px" },
  feesText: {
    textAlign: "right",
    color: darkGrey,
    fontSize: 9,
  },
  financeText: {
    fontSize: 9,
    textAlign: "center",
  },
  totalText: {
    fontSize: 13,
    fontWeight: "bold",
    textAlign: "center",
  },
  imageBlock: {
    flexDirection: "column",
    width: "15%",
    margin: "4px",
  },
  titleBlock: {
    flexDirection: "column",
    width: "75%",
    margin: "4px",
  },
  lotDetailsBlock: {
    width: "20%",
    flexDirection: "column",
    margin: "4px",
  },
  lotDescriptionBlock: {
    width: "50%",
    flexDirection: "column",
    margin: "4px",
    maxHeight: "160px",
  },
  buyerDeclarationBlock: {
    width: "50%",
    flexDirection: "column",
    margin: "4px",
    marginRight: "12px",
  },
  nameSignatureBlock: {
    width: "30%",
    marginRight: "12px",
    flexDirection: "column",
  },
  dateBlock: {
    width: "10%",
    flexDirection: "column",
  },
  declarationText: {
    fontSize: 6,
    paddingBottom: "4px",
  },
  signatureText: {
    fontSize: 6,
    borderBottom: `1 solid ${grey}`,
    paddingBottom: "18px",
    paddingTop: "4px",
  },
  paymentBlock: {
    width: "60%",
    flexDirection: "column",
  },
  collectionCode: {
    fontSize: 12,
    textAlign: "right",
    marginBottom: "2px",
    padding: "2px",
  },
  redTextMediumBold: {
    color: error,
    fontSize: 12,
    textAlign: "right",
    marginBottom: "2px",
    padding: "2px",
  },
  redTextMedium: {
    color: error,
    fontSize: 9,
    textAlign: "left",
  },
  redText: {
    color: error,
    fontSize: 6,
    textAlign: "left",
  },
  rowWidth100: {
    flexDirection: "row",
    width: "100%",
  },
  financeTitleCell: {
    width: "15%",
    border: `1 solid ${grey}`,
    padding: "4px 2px 2px 4px",
  },
  financeCell: {
    width: "20%",
    border: `1 solid ${grey}`,
    padding: "4px 0 2px 4px",
  },
  financeNoBorderCell: {
    width: "20%",
  },
  financeTitleNoBorderCell: {
    width: "15%",
  },
  financeThickLeftCell: {
    width: "20%",
    border: `1 solid ${darkGrey}`,
    borderRight: `1 solid ${grey}`,
    padding: "4px 0 2px 4px",
  },
  financeThickRightCell: {
    width: "20%",
    border: `1 solid ${darkGrey}`,
    borderLeft: `1 solid ${grey}`,
    padding: "4px 0 2px 4px",
  },
  arrowCell: {
    width: "20%",
    padding: "4px 0 0 0",
  },
  financeInfoCell: {
    width: "25%",
    padding: "2px 0 2px 4px",
  },
  totalsCell: {
    border: `2 solid ${darkGrey}`,
    width: "25%",
  },
  totalDueCell: {
    width: "25%",
  },
  paymentMethodCell: { width: "30%", border: `1 solid ${grey}`, padding: "4px" },
  paymentFeeCell: { width: "12%", border: `1 solid ${grey}`, padding: "4px" },
  paymentTotalCell: { width: "18%", border: `1 solid ${grey}`, padding: "4px" },
  paymentNoteCell: { width: "40%", border: `1 solid ${grey}`, padding: "4px" },
  bankMethodCell: { width: "30%", border: `1 solid ${grey}`, padding: "4px", backgroundColor: salesDocumentHighlight },
  bankFeeCell: { width: "12%", border: `1 solid ${grey}`, padding: "4px", backgroundColor: salesDocumentHighlight },
  bankTotalCell: { width: "18%", border: `1 solid ${grey}`, padding: "4px", backgroundColor: salesDocumentHighlight },
  bankNoteCell: { width: "40%", border: `1 solid ${grey}`, padding: "4px", backgroundColor: salesDocumentHighlight },
  bankDetails: {
    flexDirection: "column",
    width: "33%",
    padding: "0 0 5px 0",
    margin: "0 0 0 20px",
  },
  bankTitleCell: { width: "25%", padding: "1px" },
  bankInfoCell: {
    width: "75%",
    padding: "1px",
  },
  bankDetailsColumn: {
    flexDirection: "column",
    backgroundColor: salesDocumentHighlight,
    border: `1 solid ${grey}`,
    padding: "3px",
  },
  bankDetailsTitle: { padding: "2px 8px 8px 8px", textAlign: "left" },
  openingTimesDayBlock: {
    flexDirection: "column",
    width: "20%",
    padding: "2px",
  },
  openingTimesBlock: {
    flexDirection: "column",
    width: "80%",
    padding: "2px",
  },
  paymentDetailsRedNote: {
    color: error,
    fontSize: 6,
    textAlign: "left",
    minHeight: "16px",
  },
  bankDetailsText: {
    fontSize: 9,
    minHeight: "16px",
  },
  padding4: {
    padding: "4px",
  },
  padding2: {
    padding: "2px",
  },
  paddingBottom4: { paddingBottom: "4px" },
  payLink: {
    textDecoration: "underline",
    fontStyle: "solid",
    color: "#2072D0",
    fontSize: 8
  }
});
