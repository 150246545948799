import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Typography, Chip, Divider, Box, useMediaQuery, Theme, Grid, Link } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import { numberWithCommas, ellipsisText } from "../../helpers/text-format/TextFormat";
import { AppContext } from "../../contexts/AppContext";
import { AddToWatchlistIcon, RemoveFromWatchlistIcon } from "../icons/HomePageIcons";
import { Mileage, Check, Cross, Label, Unknown } from "../icons/Icons";
import * as Styles from "./styles/AuctionListItemStyles";
import { useLotMedia, Media } from "../../services/MediaService";
import { AuctionSearchResult } from "../../services/AuctionService";
import LotFieldComponent, { MileageAllow, DoesStartAllow, DoesDriveAllow, ReferenceNumberAllow } from "../lot-field/LotFieldComponent";
import { useMsal } from "../../authentication/MsalProvider";
import { IMsalContext } from "../../authentication/MsalContext";
import { LiveAuctionListItemFooter } from "./sub-components/LiveAuctionListItemFooter";
import { AuctionListItemFooter } from "./sub-components/AuctionListItemFooter";
import moment from "moment";
import { useSnackbar } from "notistack";

interface AuctionListItemProps {
  item: AuctionSearchResult;
  index: number;
}

export const AuctionListItem: React.FC<AuctionListItemProps> = ({ item, index }) => {
  const msal: IMsalContext = useMsal();
  const classes = Styles.AuctionListItemStyles();
  const context = React.useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const [isWatching, setIsWatching] = useState(context.bidderState.checkWatchlist({ auctionId: item.id }));
  const [updatingWatchlist, setUpdatingWatchlist] = useState(false);
  const [mediaUrl, setMediaUrl] = React.useState<string>();
  const [media] = useLotMedia(item.lotId, item.lotMediaCacheName);

  useEffect(() => {
    setUpdatingWatchlist(false);
  }, [isWatching]);

  useEffect(() => {
    setIsWatching(context.bidderState.checkWatchlist({ auctionId: item.id }));
  }, [context, item.id]);

  useEffect(() => {
    if (media !== null && media.length > 0) {
      const primaryMedia: Media[] = media.filter(item => {
        return item.order === 0;
      });

      if (primaryMedia.length > 0) {
        setMediaUrl(primaryMedia[0].versions.thumbnail);
      } else if (primaryMedia.length > 1) {
        setMediaUrl(primaryMedia[0].versions.thumbnail);
      } else {
        setMediaUrl(media[0].versions.thumbnail);
      }
    }
  }, [item.id, media]);

  const handleWatchlistClickEvent = (e: Event) => {
    e.stopPropagation();
    setUpdatingWatchlist(true);
    if (context.bidderState.checkWatchlist({ auctionId: item.id })) {
      context.bidderState.removeFromWatchlist(item.id).catch(() => {
        enqueueSnackbar("An error occured with your watchlist, please try again", { variant: "error" });
        setUpdatingWatchlist(false);
      });
    } else {
      context.bidderState.addToWatchlist(item.id).catch(() => {
        enqueueSnackbar("An error occured with your watchlist, please try again", { variant: "error" });
        setUpdatingWatchlist(false);
      });
    }
  };

  const getWatchingContainerClasses = () => {
    return `${classes.watchingContainer} ${isWatching ? classes.watching : classes.notWatching}`;
  };

  const getItemContainerClasses = () => {
    return `${classes.itemContainer} 
      ${isWatching ? classes.watchingContainerBorder : classes.notWatchingContainerBorder} 
      ${index % 2 ? classes.altBackground : null}`;
  };

  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const lg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const getTitleLength = () => {
    if (xs) {
      return 75;
    }

    if (sm) {
      return 75;
    }

    if (md) {
      return 55;
    }

    if (lg) {
      return 95;
    }

    return 95;
  };

  const getAuctionImageElement = () => {
    if (mediaUrl !== undefined) {
      return (
        <Grid component={RouterLink} to={`/Lot/${item.id}`}>
          <Box
            component="div"
            style={{
              height: sm ? "250px" : "100%",
              width: "auto",
              marginTop: msal.accounts.length > 0 ? (isWatching ? "-60px" : "-65px") : "0px",
              backgroundImage: `url(${mediaUrl})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          ></Box>
        </Grid>
      );
    }
    return <></>;
  };

  const getGoogleMapsLink = () => {
    return item.postcode ? (
      <Typography
        onClick={(e: any) => {
          e.stopPropagation();
        }}
        component={Link}
        href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(item.postcode)}`}
        target="_blank"

        variant="button"
        color="textPrimary"
        gutterBottom
      >
        {item.location}
      </Typography>
    ) : (
      <Typography variant="button" color="textPrimary" gutterBottom>
        {item.location}
      </Typography>
    );
  };

  const updatingWatchlistComponent = () => {
    return (
      <Grid item xs={12} className={classes.updatingWatchlist}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item>
            <CircularProgress color="primary" size={60} thickness={8} />
          </Grid>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              Updating Watchlist...
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const checkCrossBoolComponent = (value: boolean | null) => {
    if (value === true) {
      return <Check />;
    } else if (value === false) {
      return <Cross />;
    }
    return <Unknown />;
  };

  return (
    <Grid container alignItems="stretch" className={getItemContainerClasses()}>
      {updatingWatchlist ? (
        updatingWatchlistComponent()
      ) : (
        <>
          <Grid item md={3} xs={12} className={classes.imageContainer}>
            {msal.accounts.length > 0 && (
              <Box
                className={getWatchingContainerClasses()}
                onClick={(e: any) => {
                  handleWatchlistClickEvent(e);
                }}
              >
                {isWatching ? <RemoveFromWatchlistIcon /> : <AddToWatchlistIcon />}
              </Box>
            )}

            {getAuctionImageElement()}
          </Grid>
          <Grid component={RouterLink} to={`/Lot/${item.id}`} item md={9} xs={12} className={[classes.mainDetailsContainer, classes.noLinkDecoration].join(' ')}>
            <Grid container direction="column" justifyContent="center" alignItems="stretch" className={classes.searchBodyContainer}>
              <Grid item container spacing={2} alignItems="stretch">
                <Grid item md={8} sm={12} xs={12}>
                  <Grid container>
                    <Grid item className={classes.auctionTagItem}>
                      <Chip
                        size="small"
                        color="primary"
                        label={`CATEGORY ${item.damageCategory ? item.damageCategory : "Unknown"}`}
                        className={classes.category}
                      />
                    </Grid>
                    <Grid item className={classes.auctionTagItem}>
                      <Box className={classes.memberImageContainer}>
                        <img alt="logo" src={item.memberImageUrl} height="20px" width="auto"></img>
                      </Box>
                    </Grid>
                    <Grid item className={classes.auctionTagItem}>
                      <Box component={"div"}>{getGoogleMapsLink()}</Box>
                    </Grid>
                  </Grid>
                  <Box>
                    <Typography component={RouterLink} to={`/Lot/${item.id}`} variant="h6" className={classes.searchTitle}>
                      {ellipsisText(item.title, getTitleLength())}
                    </Typography>
                    {item.isFreeSubscription && (
                      <Chip
                        size="small"
                        color="secondary"
                        label="FREE BIDDER LISTING"
                        className={classes.freeBidder}
                        style={{maxWidth: "185px", display: "flex"}}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item md={4} sm={12} xs={12} className={classes.metricsColumn}>
                  <Grid container>
                    <Grid item md={12} sm={6} xs={6}>
                      <LotFieldComponent currentLotType={item.lotType} allowLotTypes={MileageAllow}>
                        <Box className={classes.metricsColumnItem}>
                          <Mileage />
                          <Box className={classes.metricsValue} component="span" color="secondary">
                            {item.mileage !== 0 ? numberWithCommas(item.mileage) : "Unknown"}
                          </Box>
                        </Box>
                      </LotFieldComponent>
                    </Grid>
                    <Grid item md={12} sm={6} xs={6}>
                      <LotFieldComponent currentLotType={item.lotType} allowLotTypes={DoesStartAllow}>
                        <Box className={classes.metricsColumnItem}>
                          {checkCrossBoolComponent(item.doesStart)}
                          <Box className={classes.metricsValue} component="span" color="secondary">
                            Starts
                          </Box>
                        </Box>
                      </LotFieldComponent>
                    </Grid>
                    <Grid item md={12} sm={6} xs={6}>
                      <LotFieldComponent currentLotType={item.lotType} allowLotTypes={DoesDriveAllow}>
                        <Box className={classes.metricsColumnItem}>
                          {checkCrossBoolComponent(item.doesDrive)}
                          <Box className={classes.metricsValue} component="span" color="secondary">
                            Drives
                          </Box>
                        </Box>
                      </LotFieldComponent>
                    </Grid>
                    <Grid item md={12} sm={6} xs={6}>
                      <LotFieldComponent currentLotType={item.lotType} allowLotTypes={ReferenceNumberAllow}>
                        <Box className={classes.metricsColumnItem}>
                          <Label />
                          <Box className={classes.metricsValue} component="span" color="secondary">
                            Ref: {item.referenceNumber}
                          </Box>
                        </Box>
                      </LotFieldComponent>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Divider className={classes.divider} />
            </Grid>
            {moment(item.auctionEnd) < moment().add(1, "hours") ? (
              <LiveAuctionListItemFooter auction={item} />
            ) : (
              <AuctionListItemFooter auction={item} />
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};
