import * as React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { AppContext } from "../../contexts/AppContext";
import { LoadingComponent } from "../loading/LoadingComponent";
import { DateToDateString } from "../../helpers/dateHelper";
import * as Styles from "./styles/RenewalStyles";
import { BidderStatus } from "../../interfaces/bidders/IBidder";
import { useSnackbar } from "notistack";
import * as StripeService from "../../services/StripeService";
import { IStripePaymentIntentResponse } from "../../interfaces/payments/IStripePaymentIntentResponse";
import { ISalvageClientSettings } from "../../interfaces/ISalvageClientSettings";
import { GetClientSettings } from "../../services/SettingsService";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { canRenewOn } from "../../contexts/canRenewOn";
import { Link } from "react-router-dom";

export function RenewalProcessComponent() {
  const { enqueueSnackbar } = useSnackbar();
  const ClientSettings: ISalvageClientSettings = GetClientSettings();
  const classes = Styles.RenewalStyles();
  let context = React.useContext(AppContext);
  const [loadingBidder, setLoadingBidder] = React.useState(true);
  const [status, setStatus] = React.useState<BidderStatus>(BidderStatus.Unknown);

  React.useEffect(() => {
    setStatus(context.bidderState.bidder.bidderStatus);
  }, [context.bidderState.bidder.bidderStatus]);

  React.useEffect(() => {
    if (context.bidderState.bidderLoaded) {

    }
  }, [context.bidderState.bidder, context.bidderState.bidderLoaded]);

  React.useEffect(() => {
    checkCallBack();
  }, []);

  const checkCallBack = () => {
    const callbackPaymentIntentId = new URLSearchParams(window.location.search).get(
      "payment_intent"
    );

    if (callbackPaymentIntentId != undefined && callbackPaymentIntentId != null) {

      StripeService.ProcessPaymentIntentSuccess(callbackPaymentIntentId)
        .then(success => {
          setLoadingBidder(false);
          let successPaymentIntent = success.parsedBody as IStripePaymentIntentResponse;
          switch (successPaymentIntent.status) {
            case "succeeded":
              enqueueSnackbar("Payment succeeded!", {
                variant: "success",
              });
              context.bidderState.getBidder(true);
              break;
            case "processing":
              enqueueSnackbar("Your payment is processing.", {
                variant: "info",
              });
              break;
            case "requires_payment_method":
              enqueueSnackbar("Your payment was not successful, please try again.", {
                variant: "error",
              });
              window.location.href = '/Renewal';
              break;
            default:
              enqueueSnackbar("Something went wrong.", {
                variant: "error",
              });
              window.location.href = '/Renewal';
              break;
          }
        })
        .catch((error: Response) => {
          enqueueSnackbar("We have been unable to process this payment. Please close your browser and try again", {
            variant: "error",
          });
        })
    } else {
      // they are not on a postback. take them back to Renewal
      window.location.href = '/Renewal';
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4" component="h1" gutterBottom className={classes.headerPadding}>
          Subscription
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {loadingBidder && <LoadingComponent label="Processing payment" />}
        {!loadingBidder && (
          <>
            {status === BidderStatus.ActiveFull && (
              <div className={classes.subscriptionContainer} style={{ backgroundColor : "#fefefe", border : "1px solid #cccccc", borderRadius: "10px", padding : "24px", marginBottom : "25px " }}>
                <CheckCircleIcon className={classes.greenTick} />
                <Typography>
                Thank you for your payment. Your current subscription will expire on {DateToDateString(new Date(context.bidderState.bidder.subscriptionEndDate))}, you can renew
                  on {DateToDateString(canRenewOn(context.bidderState.bidder.subscriptionEndDate))}. Please contact assist@salvagemarket.co.uk with any further questions.
                </Typography>
              </div>
            )}
            <Grid item xs={12}>
              <Button variant="contained" color="primary" component={Link} to="/SubscriptionHistory">
                Subscription history
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}
