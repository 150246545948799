import { ApiService, HttpResponse } from "./ApiService";
import { EncryptedData } from "./DataScrapingMonitorService";
import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { IAuctionTimesWeek } from "../interfaces/auctionTimes/IAuctionTimesWeek";
import { IPagingResults } from "../interfaces/paging/paging";
import { usePostedData, useReadonlyData } from "./useVersionedData";
import { LotDetails } from "./LotService";
import { ICountry } from "../interfaces/ICountry";
const ClientSettings: ISalvageClientSettings = GetClientSettings();

export interface Auction {
  id: string;
  status: AuctionStatus;
  lotId: string;
  memberId: string;
  memberImageUrl: string;
  auctionEnd: string;
  bidIncrement: number;
  startPrice: number;
  metadata: AuctionMetadata;
  currentHighestBid: ICurrentHighestBid;
  distanceToBidder: number;
  lotMediaCacheName: string;
}

export interface FeaturedAuction {
  id: string;
  title: string;
  lotId: string;
  damageCategory: string;
  location: string;
  auctionEnd: string;
  startPrice: number;
  currentHighestBid: ICurrentHighestBid;
  lotMediaCacheName: string;
}


export interface AuctionMetadata extends LotDetails {
  isFeatured: boolean;
  seller: string;
  siteId: string;
  siteName: string;
  line1: string;
  line2: string;
  line3: string;
  city: string;
  postcode: string;
  county: string;
  country: ICountry;
  location: string;
  latitude: number;
  longitude: number;
  encryptedData: EncryptedData;
  registrationNumberMessage: string;
}


export interface AuctionSearchResult {
  id: string;
  status: AuctionStatus;
  lotId: string;
  memberImageUrl: string;
  auctionEnd: string;
  startPrice: number;
  currentHighestBid?: number;
  lotMediaCacheName: string;
  postcode: string;
  location: string;
  title: string;
  description: string;
  lotType: string;
  referenceNumber: string;
  mileage: number;
  damageCategory: string;
  doesStart: boolean | null;
  doesDrive: boolean | null;
  currentBidsCount: number | null;
  isFreeSubscription: boolean;
}


export interface ICurrentHighestBid {
  amount: number;
}

export interface AuctionListing {
  id: string;
  status: AuctionStatus;
  auctionEnd: string;
  lotId: string;
  startPrice: number;
  title: string;
  memberImageUrl: string;
  thumbnailImageUrl: string;
}

export enum AuctionStatus {
  Undefined = 0,
  CreatedPartially = 100,
  CreatedAwaitingBucketing = 110,
  CreatedAwaitingPublishing = 120,
  Active = 200,
  ActiveButSuspended = 210,
  AuctionEndedAndWon = 300,
  AuctionEndedButNotWon = 310,
  AuctionEndedButSuspended = 320,
  AuctionCollectedRecently = 350,
  CompletedAsLotCollected = 400,
  CompletedAsArchived = 410,
  CompletedAsReListed = 420,
  DeletedBeforeBucketing = 500,
}

export interface AuctionFees {
  siteFee: number;
  hammerFee: number;
  adminFee: number;
}


export interface AuctionsWonSearchRequest {
  search?: string | null;
}

export const UseDefaultAuctionsWonRequest = (): AuctionsWonSearchRequest => {
  return { search: null };
}

export const useAuction = (auctionId: string, bidderAuthenticated: boolean, onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<Auction>(
    `${bidderAuthenticated ? ClientSettings.AuctionPrivateApiUrl : ClientSettings.AuctionPublicApiUrl}/auctions/id/${auctionId}`,
    onError
  );

export const useAuctionFees = (auctionId: string, onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<AuctionFees>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/id/${auctionId}/fees`, onError);

export const useFeaturedAuction = (page: number, pageSize: number, onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<IPagingResults<FeaturedAuction>>(`${ClientSettings.AuctionPublicApiUrl}/auctions/featured/${page}/${pageSize}`, onError);

export async function GetAuctionTimes() {
  const auction = ApiService().Get<IAuctionTimesWeek[]>(`${ClientSettings.AuctionPublicApiUrl}/auctions/active/times`);
  return auction;
}

export const useAuctionsBidOn = (page: number, pageSize: number, onError?: (httpStatusCode: number, resultBody?: string) => void) =>
  usePostedData<IPagingResults<AuctionListing>, AuctionsWonSearchRequest>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/bids/paged/${page}/${pageSize}`, onError);

export const useAuctionsWon = (page: number, pageSize: number, onError?: (httpStatusCode: number, resultBody?: string) => void) =>
  usePostedData<IPagingResults<AuctionListing>, AuctionsWonSearchRequest>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/won/paged/${page}/${pageSize}`, onError);

  export const getIncrementScale = async (auctionId: string, currentIncrement: number):Promise<HttpResponse<number[]>>  => {
    return await ApiService().Get<number[]>(`${ClientSettings.AuctionPublicApiUrl}/auctions/id/${auctionId}/bids/getIncrementScale/${currentIncrement}`);  
    };

    export const getStaticIncrements = async ():Promise<HttpResponse<any[]>>  => {
      return await ApiService().Get<any[]>(`${ClientSettings.AuctionPublicApiUrl}/auctions/bids/getStaticIncrements`);  
      };
