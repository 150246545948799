import React from "react";
import { Button, Chip, Divider, Grid, Typography } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link as RouterLink } from "react-router-dom";
import { CalendarToday, DirectionsCar, LocalGasStation, LocationOn, Settings, Speed, VpnKey } from "@material-ui/icons";
import * as Styles from "./styles/SavedSearchItemStyles";
import { SavedSearchResult } from "../../services/SearchService";

interface SavedSearchItemProps {
  item: SavedSearchResult;
  index: number;
  loading: boolean;
  onDeleteEvent(id: string | undefined): void;
  onViewSeachEvent(queryString: string);
  isDialog?: boolean;
}

export const SavedSearchItem: React.FC<SavedSearchItemProps> = ({ item, index, loading, onDeleteEvent, onViewSeachEvent, isDialog }) => {
  const classes = Styles.SavedSearchItemStyles();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid container alignItems="stretch" className={classes.savedSearchListItemContainer}>
      <Grid item xs={12} style={{ marginBottom: "1rem" }}>
        {!item?.damageCategories?.includes("") && item.damageCategories?.map(cat => (
          <Chip size="small" color="primary" label={"Category " + cat} style={{ textTransform: "uppercase", marginBottom: "0.5rem", marginRight: "0.5rem" }} key={cat} />
        ))}

        {item?.freeSubscriptionOnly && (
          <Chip
            size="small"
            color="secondary"
            label="FREE LISTINGS ONLY"
            className={classes.freeBidder}
          />
        )}
      </Grid>

      <Grid item xs={6} md={9}>
        <Typography variant="h5" component="h5" style={{ textTransform: "uppercase" }}>
          {item.name}
        </Typography>
      </Grid>

      <Grid item xs={6} md={3} className={classes.customSpacing}>
        <LocationOn />
        <span>{item.postcode}</span>
      </Grid>

      {item.distance != null && item.distance.length > 0 && !item.distance?.includes("") && (
        <Grid item className={classes.savedSearchIcon}>
          <Speed />
          {item.distance.join(", ")}
        </Grid>
      )}

      {item.makes != null && item.makes.length > 0 && !item.makes?.includes("") && (
        <Grid item className={classes.savedSearchIcon}>
          <DirectionsCar />
          {item.makes.join(", ")}
        </Grid>
      )}

      {item.models != null && item?.models.length > 0 && !item.models?.includes("") && (
        <Grid item className={classes.savedSearchIcon}>
          <DirectionsCar />
          {item.models.join(", ")}
        </Grid>
      )}

      {item.fuelTypes != null && item.fuelTypes.length > 0 && !item.fuelTypes?.includes("") && (
        <Grid item className={classes.savedSearchIcon}>
          <LocalGasStation />
          {item.fuelTypes.join(", ")}
        </Grid>
      )}

      {item.transmissionTypes != null && item.transmissionTypes.length > 0 && !item.transmissionTypes?.includes("") && (
        <Grid item className={classes.savedSearchIcon}>
          <Settings />
          {item.transmissionTypes.join(", ")}
        </Grid>
      )}

      {item.years != null && item.years.length > 0 && !item.years?.includes("") && (
        <Grid item className={classes.savedSearchIcon}>
          <CalendarToday />
          {item.years.join(", ")}
        </Grid>
      )}

      {item.startDrives?.includes("Starts") && (
        <Grid item className={classes.savedSearchIcon}>
          <VpnKey />
          Starts
        </Grid>
      )}

      {item.startDrives?.includes("Drives") && (
        <Grid item className={classes.savedSearchIcon}>
          <DirectionsCar />
          Drives
        </Grid>
      )}

      <Grid item xs={12}>
        <Divider />
        <Grid container justifyContent="flex-end" spacing-md={1} style={{ marginTop: "1rem" }}>
          <Grid item xs={12} md={isDialog ? 6 : 2} style={{ paddingRight: isMdUp ? "7.5px" : "0px" }}>
            <Button disabled={loading} className={classes.deleteButton} variant="contained" color="secondary" onClick={() => onDeleteEvent(item.id)} fullWidth>
              Delete
            </Button>
          </Grid>
          <Grid item xs={12} md={isDialog ? 6 : 2} style={{ paddingRight: isMdUp ? "7.5px" : "0px" }}>
            {isDialog ? (
              <Button disabled={loading} onClick={() => onViewSeachEvent(`/Search?${item.queryString}`)} variant="contained" color="primary" fullWidth>
                View Search
              </Button>
            ) : (
              <Button disabled={loading} component={RouterLink} to={`/Search?${item.queryString}`} variant="contained" color="primary" fullWidth>
                View Search
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
